import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";
import braincogBackgroundImage from "../assets/images/engines/braincog_bg.jpg";
import bornBackgroundImage from "../assets/images/engines/born_bg.jpg";
import brainknowBackgroundImage from "../assets/images/engines/brainknow_bg.jpg";
import { Fade, Slide } from "react-awesome-reveal";

function BrainCog_Born({brainknow = false}) {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const heroVideoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div id={"engines"} className={"flex justify-center items-center flex-col pt-36 px-5 bg-black"}>
            <div className={" max-w-4xl mx-auto"}>
                <div className={`text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-br from-[#ff6995] to-[#73cdff] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.1em]' : 'tracking-[.02em]'}`}>{t('engine_section_title')}</div>
                {/*<div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-10 text-center"}>{t('engine_section_intro')}</div>*/}
                {/*braincog*/}
                <div className={"mt-20"}>
                    <div className={`bg-clip-text text-transparent bg-gradient-to-b from-blue-400/100 to-blue-400/30 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_braincog_title')}</div>
                    <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_braincog_subtitle')}</div>
                    <Fade triggerOnce>
                        <a
                            className={"bg-contain bg-center bg-no-repeat bg-black/10 hover:bg-black/0 transition-all duration-300 ease-in-out bg-blend-multiply min-h-[220px] md:min-h-[420px] w-full flex justify-evenly items-center flex-wrap p-6 rounded-3xl"}
                            style={{backgroundImage: `url(${braincogBackgroundImage})`}}
                            href={"http://brain-cog.network"}
                        >
                        </a>
                    </Fade>
                </div>
                {/*born*/}
                <div className={"mt-20"}>
                    <div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-lime-500/100 to-lime-500/30 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_born_title')}</div>
                    <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_born_subtitle')}</div>
                    <Fade delay={100} triggerOnce>
                        <a
                            className={"bg-cover bg-black/70 hover:bg-black/60 transition-all duration-300 ease-in-out bg-blend-multiply bg-bottom bg-no-repeat sm:min-h-[400px] w-full flex justify-evenly items-center flex-wrap p-6 rounded-3xl"}
                            style={{backgroundImage: `url(${bornBackgroundImage})`}}
                            href={"http://born.brain-cog.network"}
                        >
                            <div className={" lg:max-w-[60%]"}>
                                <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-2 mb-2"}>{t('engine_born_intro')}</div>
                                <div className={"text-xl text-slate-100 font-normal leading-normal mt-10"}>{t('engine_born_intro_suffix')}</div>
                            </div>
                            <Fade delay={700} triggerOnce>
                                <img src={process.env.PUBLIC_URL + `/images/engines/logo_born.png`} loading="lazy"
                                     alt={"born"}
                                     className={"transition-all duration-500 ease-in-out rounded max-h-0 lg:max-h-[350px]"}
                                />
                            </Fade>
                        </a>
                    </Fade>
                </div>
                {/*brainknow*/}
                {brainknow &&
                    <div className={"mt-20"}>
                        <div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-purple-200 to-purple-900 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_brainknow_title')}</div>
                        <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_brainknow_subtitle')}</div>
                        <Fade delay={100} triggerOnce>
                        <a
                                className={"bg-cover bg-black/70 hover:bg-black/60 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat sm:min-h-[400px] w-full flex justify-start items-center flex-wrap p-6 rounded-3xl"}
                                style={{backgroundImage: `url(${brainknowBackgroundImage})`}}
                                href={"http://brain-knowledge-engine.org/"}
                            >
                                <div className={"lg:ml-8 lg:max-w-[60%]"}>
                                    <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-2 mb-2 lg:max-w-[500px]"}>{t('engine_brainknow_intro')}</div>
                                    <div className={"text-xl text-slate-100 font-normal leading-normal mt-10"}>{t('engine_brainknow_intro_suffix')}</div>
                                </div>
                            </a>
                        </Fade>
                    </div>
                }
            </div>
        </div>
    )
}

export default BrainCog_Born