import '../styles/Common.css';
import '../styles/BrainSimulation.css';
import {useTranslation, Trans} from "react-i18next";
import SEO from "./SEO";

import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Listbox, ListboxButton, ListboxOption, ListboxOptions, Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild, Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon, XMarkIcon, HomeIcon, LanguageIcon} from '@heroicons/react/20/solid'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import NeuronCarousel from "./NeuronCarousel";
import heroBackgroundImage from "../assets/images/hero_background/brain_simulation_hero_background_0.jpg";
import humanEngineButtonBackgroundImage from "../assets/images/hero_background/brain_simulation_hero_background_2.jpeg";

// Propofol induces anesthesia（丙泊酚诱导麻醉）


const BrainSimulation = () => {
    const {t, i18n} = useTranslation(['brain_simulation', 'common', 'navbar', 'footer', 'html_title'])
    const isChinese = i18n.language === 'zh';
    const current_year = new Date().getFullYear();

    // 神经模拟成果展示弹窗
    const neuronCarouselTextList = t('engine_readme_text', { returnObjects: true });
    let [neuronCarouselIsOpen, setNeuronCarouselIsOpen] = useState(true)
    function openNeuronCarousel() {
        setNeuronCarouselIsOpen(true)
    }
    function closeNeuronCarousel() {
        setNeuronCarouselIsOpen(false)
    }
        
    // 引擎说明弹窗
    const engineReadmeTextList = t('engine_readme_text', { returnObjects: true });
    let [engineReadmeIsOpen, setEngineReadmeIsOpen] = useState(true)
    function openEngineReadme() {
        setEngineReadmeIsOpen(true)
    }
    function closeEngineReadme() {
        setEngineReadmeIsOpen(false)
    }
    
    // 场景与神经元类型
    const allScenarios = [
        {id: 1, name: 'Flappy Bird', readme: 'task_readme_flappy_bird'},
        {id: 2, name: 'Pain empathy', readme: 'task_readme_pain_empathy'},
        {id: 3, name: 'Theory of Mind', readme: 'task_readme_tom'},
        {id: 4, name: 'Propofol induces anesthesia', readme: 'task_readme_propofol'},
        {id: 5, name: 'The antidepressant effect of esketamine', readme: 'task_readme_depression'}
    ]

    const allBrainRegions = [
        {id: 1, name_short: 'L.BSTS', name: 'ctx-lh-bankssts'},
        {id: 2, name_short: 'L.CACG', name: 'ctx-lh-caudalanteriorcingulate'},
        {id: 3, name_short: 'L.CMFG', name: 'ctx-lh-caudalmiddlefrontal'},
        {id: 4, name_short: 'L.CU', name: 'ctx-lh-cuneus'},
        {id: 5, name_short: 'L.EC', name: 'ctx-lh-entorhinal'},
        {id: 6, name_short: 'L.FG', name: 'ctx-lh-fusiform'},
        {id: 7, name_short: 'L.IPG', name: 'ctx-lh-inferiorparietal'},
        {id: 8, name_short: 'L.ITG', name: 'ctx-lh-inferiortemporal'},
        {id: 9, name_short: 'L.ICG', name: 'ctx-lh-isthmuscingulate'},
        {id: 10, name_short: 'L.LOG', name: 'ctx-lh-lateraloccipital'},
        {id: 11, name_short: 'L.LOFG', name: 'ctx-lh-lateralorbitofrontal'},
        {id: 12, name_short: 'L.LG', name: 'ctx-lh-lingual'},
        {id: 13, name_short: 'L.MOFG', name: 'ctx-lh-medialorbitofrontal'},
        {id: 14, name_short: 'L.MTG', name: 'ctx-lh-middletemporal'},
        {id: 15, name_short: 'L.PHIG', name: 'ctx-lh-parahippocampal'},
        {id: 16, name_short: 'L.PaCG', name: 'ctx-lh-paracentral'},
        {id: 17, name_short: 'L.POP', name: 'ctx-lh-parsopercularis'},
        {id: 18, name_short: 'L.POR', name: 'ctx-lh-parsorbitalis'},
        {id: 19, name_short: 'L.PTR', name: 'ctx-lh-parstriangularis'},
        {id: 20, name_short: 'L.PCAL', name: 'ctx-lh-pericalcarine'},
        {id: 21, name_short: 'L.PoCG', name: 'ctx-lh-postcentral'},
        {id: 22, name_short: 'L.PCG', name: 'ctx-lh-posteriorcingulate'},
        {id: 23, name_short: 'L.PrCG', name: 'ctx-lh-precentral'},
        {id: 24, name_short: 'L.PCU', name: 'ctx-lh-precuneus'},
        {id: 25, name_short: 'L.RACG', name: 'ctx-lh-rostralanteriorcingulate'},
        {id: 26, name_short: 'L.RMFG', name: 'ctx-lh-rostralmiddlefrontal'},
        {id: 27, name_short: 'L.SFG', name: 'ctx-lh-superiorfrontal'},
        {id: 28, name_short: 'L.SPG', name: 'ctx-lh-superiorparietal'},
        {id: 29, name_short: 'L.STG', name: 'ctx-lh-superiortemporal'},
        {id: 30, name_short: 'L.SMG', name: 'ctx-lh-supramarginal'},
        {id: 31, name_short: 'L.FP', name: 'ctx-lh-frontalpole'},
        {id: 32, name_short: 'L.TP', name: 'ctx-lh-temporalpole'},
        {id: 33, name_short: 'L.TTG', name: 'ctx-lh-transversetemporal'},
        {id: 34, name_short: 'L.IN', name: 'ctx-lh-insula'},
        {id: 35, name_short: 'L.CER', name: 'Left-Cerebellum-Cortex'},
        {id: 36, name_short: 'R.BSTS', name: 'ctx-rh-bankssts'},
        {id: 37, name_short: 'R.CACG', name: 'ctx-rh-caudalanteriorcingulate'},
        {id: 38, name_short: 'R.CMFG', name: 'ctx-rh-caudalmiddlefrontal'},
        {id: 39, name_short: 'R.CU', name: 'ctx-rh-cuneus'},
        {id: 40, name_short: 'R.EC', name: 'ctx-rh-entorhinal'},
        {id: 41, name_short: 'R.FG', name: 'ctx-rh-fusiform'},
        {id: 42, name_short: 'R.IPG', name: 'ctx-rh-inferiorparietal'},
        {id: 43, name_short: 'R.ITG', name: 'ctx-rh-inferiortemporal'},
        {id: 44, name_short: 'R.ICG', name: 'ctx-rh-isthmuscingulate'},
        {id: 45, name_short: 'R.LOG', name: 'ctx-rh-lateraloccipital'},
        {id: 46, name_short: 'R.LOFG', name: 'ctx-rh-lateralorbitofrontal'},
        {id: 47, name_short: 'R.LG', name: 'ctx-rh-lingual'},
        {id: 48, name_short: 'R.MOFG', name: 'ctx-rh-medialorbitofrontal'},
        {id: 49, name_short: 'R.MTG', name: 'ctx-rh-middletemporal'},
        {id: 50, name_short: 'R.PHIG', name: 'ctx-rh-parahippocampal'},
        {id: 51, name_short: 'R.PaCG', name: 'ctx-rh-paracentral'},
        {id: 52, name_short: 'R.POP', name: 'ctx-rh-parsopercularis'},
        {id: 53, name_short: 'R.POR', name: 'ctx-rh-parsorbitalis'},
        {id: 54, name_short: 'R.PTR', name: 'ctx-rh-parstriangularis'},
        {id: 55, name_short: 'R.PCAL', name: 'ctx-rh-pericalcarine'},
        {id: 56, name_short: 'R.PoCG', name: 'ctx-rh-postcentral'},
        {id: 57, name_short: 'R.PCG', name: 'ctx-rh-posteriorcingulate'},
        {id: 58, name_short: 'R.PrCG', name: 'ctx-rh-precentral'},
        {id: 59, name_short: 'R.PCU', name: 'ctx-rh-precuneus'},
        {id: 60, name_short: 'R.RACG', name: 'ctx-rh-rostralanteriorcingulate'},
        {id: 61, name_short: 'R.RMFG', name: 'ctx-rh-rostralmiddlefrontal'},
        {id: 62, name_short: 'R.SFG', name: 'ctx-rh-superiorfrontal'},
        {id: 63, name_short: 'R.SPG', name: 'ctx-rh-superiorparietal'},
        {id: 64, name_short: 'R.STG', name: 'ctx-rh-superiortemporal'},
        {id: 65, name_short: 'R.SMG', name: 'ctx-rh-supramarginal'},
        {id: 66, name_short: 'R.FP', name: 'ctx-rh-frontalpole'},
        {id: 67, name_short: 'R.TP', name: 'ctx-rh-temporalpole'},
        {id: 68, name_short: 'R.TTG', name: 'ctx-rh-transversetemporal'},
        {id: 69, name_short: 'R.IN', name: 'ctx-rh-insula'},
        {id: 70, name_short: 'R.CER', name: 'Right-Cerebellum-Cortex'},
        {id: 71, name_short: 'L.TH', name: 'Left-Thalamus-Proper'},
        {id: 72, name_short: 'L.CA', name: 'Left-Caudate'},
        {id: 73, name_short: 'L.PU', name: 'Left-Putamen'},
        {id: 74, name_short: 'L.PA', name: 'Left-Pallidum'},
        {id: 75, name_short: 'L.HI', name: 'Left-Hippocampus'},
        {id: 76, name_short: 'L.AM', name: 'Left-Amygdala'},
        {id: 77, name_short: 'L.AC', name: 'Left-Accumbens-area'},
        {id: 78, name_short: 'R.TH', name: 'Right-Thalamus-Proper'},
        {id: 79, name_short: 'R.CA', name: 'Right-Caudate'},
        {id: 80, name_short: 'R.PU', name: 'Right-Putamen'},
        {id: 81, name_short: 'R.PA', name: 'Right-Pallidum'},
        {id: 82, name_short: 'R.HI', name: 'Right-Hippocampus'},
        {id: 83, name_short: 'R.AM', name: 'Right-Amygdala'},
        {id: 84, name_short: 'R.AC', name: 'Right-Accumbens-area'}
    ];

    const allNeuronTypes = [
        {id: 1, name: 'Pyramidal Cells'},
        {id: 2, name: 'Interneurons'},
    ]

    const functionalCombinations = [
        {scenarioId: 1, brainVideoName: 'flappy_bird', scenarioVideoName: 'flappy_bird', brainRegionIds: [24, 59, 1, 36, 30, 65, 26, 61, 25, 60, 16, 51, 18, 53, 17, 52,72,73,74,79,80,81,22,57,15,50,20,55,71,78], neuronTypeIds: []},
        {scenarioId: 2, brainVideoName: 'robot_pain_empathy', scenarioVideoName: 'robot_pain_empathy', brainRegionIds: [24, 59, 1, 36, 30, 65, 26, 61, 25, 60, 16, 51, 18, 53, 17, 52, 22, 57, 15, 50, 20, 55, 8, 43, 27, 62, 29, 64, 23, 58, 12, 47, 10, 45], neuronTypeIds: []},
        {scenarioId: 3, brainVideoName: 'tom', scenarioVideoName: 'tom', brainRegionIds: [8, 43, 24, 59, 1, 36, 30, 65, 26, 61, 25, 60, 16, 51, 18, 53, 17, 52, 29, 64, 27, 62], neuronTypeIds: []},
        {scenarioId: 4, brainVideoName: 'propofol', scenarioVideoName: 'propofol', brainRegionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84], neuronTypeIds: []},
        {scenarioId: 5, brainVideoName: 'depression', scenarioVideoName: 'depression', brainRegionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84], neuronTypeIds: []},
    ]

    const [selectedScenario, setSelectedScenario] = useState(allScenarios[4])

    const [queryBrainRegion, setQueryBrainRegion] = useState('')
    const [selectedBrainRegions, setSelectedBrainRegions] = useState([allBrainRegions[0]])
    const filteredBrainRegions =
        queryBrainRegion === ''
            ? allBrainRegions
            : allBrainRegions.filter((brainRegion) => {
                return brainRegion.name.toLowerCase().includes(queryBrainRegion.toLowerCase()) || brainRegion.name_short.toLowerCase().includes(queryBrainRegion.toLowerCase())
            })
    const handleDeleteBrainRegion = (brainRegionId) => {
        setSelectedBrainRegions(prevSelectedBrainRegions => prevSelectedBrainRegions.filter(region => region.id !== brainRegionId));
    }

    const [queryNeuronType, setQueryNeuronType] = useState('')
    const [selectedNeuronTypes, setSelectedNeuronTypes] = useState(allNeuronTypes)
    const filteredNeuronTypes =
        queryNeuronType === ''
            ? allNeuronTypes
            : allNeuronTypes.filter((neuronType) => {
                return neuronType.name.toLowerCase().includes(queryNeuronType.toLowerCase())
            })
    const handleDeleteNeuronType = (neuronTypeId) => {
        setSelectedNeuronTypes(prevSelectedNeuronTypes => prevSelectedNeuronTypes.filter(region => region.id !== neuronTypeId));
    }

    // 根据选择来确定要显示的视频
    const [brainVideoToShow, setBrainVideoToShow] = useState('videos/brain_simulation/brains/flappy_bird.mp4');
    const [scenarioVideoToShow, setScenarioVideoToShow] = useState('videos/brain_simulation/scenarios/flappy_bird.mp4');
    const [videoKey, setVideoKey] = useState(0); // Unique key for the video element

    function getVideoNamesToShow(selectedScenario, selectedBrainRegions, selectedNeuronTypes) {
        const foundItem = functionalCombinations.find(item => item.scenarioId === selectedScenario.id);
        if (foundItem) {
            const allBrainRegionsInSelection = foundItem.brainRegionIds.every(id => selectedBrainRegions.some(region => region.id === id));
            const allNeuronTypesInSelection = foundItem.neuronTypeIds.every(id => selectedNeuronTypes.some(type => type.id === id));
            if (allBrainRegionsInSelection && allNeuronTypesInSelection) {
                return {brainVideoName: foundItem.brainVideoName, scenarioVideoName: foundItem.scenarioVideoName};
            } else {
                return {brainVideoName: 'empty_brain', scenarioVideoName: 'mission-aborted'}
            }
        } else {
            return {brainVideoName: 'empty_brain', scenarioVideoName: 'mission-aborted'};
        }
    }

    useEffect(() => {
        const presetBrainRegionIds = functionalCombinations.find(item => item.scenarioId === selectedScenario.id)?.brainRegionIds || [];
        const filteredBrainRegions = allBrainRegions.filter(region => presetBrainRegionIds.includes(region.id));
        setSelectedBrainRegions(filteredBrainRegions);
    }, [selectedScenario]);

    useEffect(() => {
        let {brainVideoName, scenarioVideoName} = getVideoNamesToShow(selectedScenario, selectedBrainRegions, selectedNeuronTypes)
        setBrainVideoToShow('videos/brain_simulation/brains/' + brainVideoName + '.mp4')
        setScenarioVideoToShow('videos/brain_simulation/scenarios/'+scenarioVideoName+'.mp4')
        setVideoKey((prevKey) => prevKey + 1);
    }, [selectedScenario, selectedBrainRegions, selectedNeuronTypes]);

    return (
        <div className={"bg-black h-screen flex items-center justify-center"}>
            <SEO title={t('brain_simulation', {ns: 'html_title'})}></SEO>
            {/*side bar*/}
            <div className={"bg-neutral-800 h-screen w-[27rem] px-4 pt-3 pb-4 overflow-y-auto flex flex-col justify-between items-center"}>
                <div className={""}>
                    {/*navs*/}
                    <div className="flex justify-evenly align-middle pb-2 border-b-neutral-700 border-b-2 border-solid text-[16px] font-medium leading-6 text-white/50 tracking-wide">
                        <div className={""}>
                            <HomeIcon className="w-3 h-3 mr-1 fill-white/60 inline"/>
                            <a href={"/"} rel="noreferrer" className={"hover:text-white/60"}>{t('home', {ns: "navbar"})}</a>
                        </div>
                        <div className={""}>
                            <LanguageIcon className="w-3 h-3 mr-1 fill-white/60 inline"/>
                            {i18n.resolvedLanguage === "zh" &&
                                <a onClick={() => i18n.changeLanguage("en")} className="cursor-pointer hover:text-white/60">English</a>
                            }
                            {i18n.resolvedLanguage === "en" &&
                                <a onClick={() => i18n.changeLanguage("zh")} className="cursor-pointer hover:text-white/60">中文</a>
                            }
                        </div>
                    </div>
                    <div className={`pt-10 text-white text-xl font-bold text-center ${isChinese ? 'tracking-widest' : 'tracking-[.02em]'}`}>{t('title')}</div>
                    <div className={"mt-8 space-y-3"}>
                        <Button
                            onClick={openEngineReadme}
                            className="w-full px-4 text-sm font-medium text-white tracking-wide bg-white/5 hover:bg-white/10 transition my-2 py-2 rounded-lg cursor-pointer"
                        >
                            {t('engine_readme_button')}
                        </Button>
                        <div className="">
                            <Listbox value={selectedScenario} onChange={setSelectedScenario}>
                                <div className="text-white mb-1 text-base">{t('choose_task')}：</div>
                                <ListboxButton
                                    className={clsx(
                                        'relative block w-full rounded-lg bg-white/5 py-1.5 pr-8 pl-3 text-left text-sm/6 text-white',
                                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                    )}
                                >
                                    {selectedScenario.name}
                                    <ChevronDownIcon
                                        className="group pointer-events-none absolute top-2.5 right-2.5 w-4 h-4 fill-white/60 group-data-[hover]:fill-white"
                                        aria-hidden="true"
                                    />
                                </ListboxButton>
                                <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <ListboxOptions
                                        anchor="bottom"
                                        className="w-[var(--button-width)] rounded-xl border border-white/5 bg-neutral-700 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                                    >
                                        {allScenarios.map((scenario) => (
                                            <ListboxOption
                                                key={scenario.name}
                                                value={scenario}
                                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                                            >
                                                <CheckIcon className="invisible w-4 h-4 fill-white group-data-[selected]:visible"/>
                                                <div className="text-sm/6 text-white">{scenario.name}</div>
                                            </ListboxOption>
                                        ))}
                                    </ListboxOptions>
                                </Transition>
                            </Listbox>
                        </div>
                        <div className={"text-center text-white text-lg"}>&#8226;</div>
                        <div className="">
                            <Combobox multiple value={selectedBrainRegions} onChange={setSelectedBrainRegions} onClose={() => setQueryBrainRegion('')}>
                                <div className="text-white mb-1 text-base">{t('choose_brain_region')}：</div>
                                <div className="relative">
                                    <ComboboxInput
                                        className={clsx(
                                            'w-full rounded-lg border-none bg-white/5 py-1.5 pr-8 pl-3 text-sm/6 text-white',
                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                        )}
                                        placeholder={t('choose_brain_region_placeholder')}
                                        displayValue={(brainRegion) => brainRegion?.name}
                                        onChange={(event) => setQueryBrainRegion(event.target.value)}
                                    />
                                    <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                                        <ChevronDownIcon className="w-4 h-4 fill-white/60 group-data-[hover]:fill-white"/>
                                    </ComboboxButton>
                                </div>
                                <Transition
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQueryBrainRegion('')}
                                >
                                    <ComboboxOptions
                                        anchor="bottom"
                                        className="w-[var(--input-width)] rounded-xl border border-white/5 bg-neutral-700 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                                    >
                                        {filteredBrainRegions.map((brainRegion) => (
                                            <ComboboxOption
                                                key={brainRegion.id}
                                                value={brainRegion}
                                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                                            >
                                                <CheckIcon className="invisible w-4 h-4 fill-white group-data-[selected]:visible"/>
                                                <div className="text-sm/6 text-white">{brainRegion.name_short} ({brainRegion.name})</div>
                                            </ComboboxOption>
                                        ))}
                                    </ComboboxOptions>
                                </Transition>
                            </Combobox>
                            <div className={"my-5"}>
                                <div className="text-white mb-1 text-base">{t('activated_brain_region')}&nbsp;({selectedBrainRegions.length})：</div>
                                <div className="min-h-[108px] max-h-40 overflow-y-auto bg-white/5 my-2 py-3 rounded-lg">
                                    {selectedBrainRegions && selectedBrainRegions.length > 0 && (
                                        <ul className={"space-y-1 pl-4"}>
                                            {selectedBrainRegions.map((brainRegion) => (
                                                <li key={brainRegion.id} className={"text-base text-white/80"}>
                                                    {brainRegion.name_short} ({brainRegion.name})
                                                    <span
                                                        className="cursor-pointer ml-1"
                                                        onClick={() => handleDeleteBrainRegion(brainRegion.id)}
                                                    >
                                                    <XMarkIcon className="w-3 h-3 fill-white/60 hover:fill-white/100 inline"/>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"text-center text-white text-md"}>&#8226;</div>
                        <div className="">
                            {/*                        <Combobox multiple value={selectedNeuronTypes} onChange={setSelectedNeuronTypes} onClose={() => setQueryNeuronType('')} disabled>
                                <div className="text-white mb-1 text-base">{t('choose_neuron_type')}：</div>
                                <div className="relative">
                                    <ComboboxInput
                                        className={clsx(
                                            'w-full rounded-lg border-none bg-white/5 py-1.5 pr-8 pl-3 text-sm/6 text-white',
                                            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                        )}
                                        placeholder={t('choose_neuron_type_placeholder')}
                                        displayValue={(neuronType) => neuronType?.name}
                                        onChange={(event) => setQueryNeuronType(event.target.value)}
                                    />
                                    <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                                        <ChevronDownIcon className="w-4 h-4 fill-white/60 group-data-[hover]:fill-white"/>
                                    </ComboboxButton>
                                </div>
                                <Transition
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQueryNeuronType('')}
                                >
                                    <ComboboxOptions
                                        anchor="bottom"
                                        className="w-[var(--input-width)] rounded-xl border border-white/5 bg-neutral-700 p-1 [--anchor-gap:var(--spacing-1)] empty:hidden"
                                    >
                                        {filteredNeuronTypes.map((neuronType) => (
                                            <ComboboxOption
                                                key={neuronType.id}
                                                value={neuronType}
                                                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                                            >
                                                <CheckIcon className="invisible w-4 h-4 fill-white group-data-[selected]:visible"/>
                                                <div className="text-sm/6 text-white">{neuronType.name}</div>
                                            </ComboboxOption>
                                        ))}
                                    </ComboboxOptions>
                                </Transition>
                            </Combobox>*/}
                            <div className={"my-5"}>
                                <div className="text-white mb-1 text-base">{t('activated_neuron_type')}&nbsp;({selectedNeuronTypes.length})：</div>
                                <div className="min-h-[108px] max-h-40 overflow-y-auto bg-white/5 my-2 py-3 rounded-lg">
                                    {selectedNeuronTypes && selectedNeuronTypes.length > 0 && (
                                        <ul className={"space-y-1 pl-4"}>
                                            {selectedNeuronTypes.map((neuronType) => (
                                                <li key={neuronType.id} className={"text-base text-white/80"}>
                                                    {neuronType.name}
                                                    {/*                                                <span
                                                        className="cursor-pointer ml-1"
                                                        onClick={() => handleDeleteNeuronType(neuronType.id)}
                                                    >
                                                    <XMarkIcon className="w-3 h-3 fill-white/60 hover:fill-white/100 inline"/>
                                                    </span>*/}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className={"text-neutral-500 text-center text-xs font-normal"}>
                        Copyright © 2023-{current_year} <a href={"/"} rel="noreferrer">{t('project_name_short', {ns: "common"})}. {t('reserve_all', {ns: "footer"})}</a>
                    </div>
                </div>
            </div>
            {/*main panel*/}
            <div className="relative py-2 w-full h-screen flex flex-col justify-evenly items-center overflow-hidden">
                {/*NeuronCarousel*/}
                {/*                <>
                    <Transition appear show={neuronCarouselIsOpen}>
                        <Dialog as="div" className="relative z-20 focus:outline-none" onClose={closeNeuronCarousel}>
                            <div className="fixed inset-0 z-20 w-screen overflow-y-auto bg-black/80">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-2xl rounded-xl bg-white/10 px-10 pt-10 pb-5 backdrop-blur-2xl overflow-y-auto">
                                            <DialogTitle as="h3" className="text-lg/7 font-medium text-white">
                                                {t('engine_readme_title')}
                                            </DialogTitle>
                                            <NeuronCarousel />
                                            <div className="mt-8 flex justify-center">
                                                <Button
                                                    className="w-full px-4 text-sm font-medium text-white tracking-wide bg-white/5 hover:bg-white/10 transition my-2 py-2 rounded-lg cursor-pointer"
                                                    onClick={closeNeuronCarousel}
                                                >
                                                    {t('engine_readme_close_button')}
                                                </Button>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>*/}

                {/*EngineReadme*/}
{/*                <>
                    <Transition appear show={engineReadmeIsOpen}>
                        <Dialog as="div" className="relative z-10 focus:outline-none" onClose={closeEngineReadme}>
                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black/80">
                                <div className="flex min-h-full items-center justify-center p-4">
                                    <TransitionChild
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 transform-[scale(95%)]"
                                        enterTo="opacity-100 transform-[scale(100%)]"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 transform-[scale(100%)]"
                                        leaveTo="opacity-0 transform-[scale(95%)]"
                                    >
                                        <DialogPanel className="w-full max-w-2xl rounded-xl bg-white/10 px-10 pt-10 pb-5 backdrop-blur-2xl overflow-y-auto">
                                            <DialogTitle as="h3" className="text-lg/7 font-medium text-white">
                                                {t('engine_readme_title')}
                                            </DialogTitle>
                                            <div className="mt-4 text-base/7 text-white/60 space-y-2">
                                                {Array.isArray(engineReadmeTextList) && engineReadmeTextList.map((item, index) => (
                                                    <p key={index}>{item}</p>
                                                ))}
                                            </div>
                                            <div className="mt-8 flex justify-center">
                                                <Button
                                                    className="w-full px-4 text-sm font-medium text-white tracking-wide bg-white/5 hover:bg-white/10 transition my-2 py-2 rounded-lg cursor-pointer"
                                                    onClick={closeEngineReadme}
                                                >
                                                    {t('engine_readme_close_button')}
                                                </Button>
                                            </div>
                                        </DialogPanel>
                                    </TransitionChild>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>*/}
                {/*TaskReadme*/}
                <div className="absolute top-5 left-5">
                    <div className="mx-auto w-full max-w-lg divide-y divide-white/5 rounded-lg bg-neutral-800">
                        <Disclosure as="div" className="py-4 px-6" defaultOpen={true} data-open={!engineReadmeIsOpen}>
                            <DisclosureButton className="group flex w-full items-center justify-between">
                                <span className="text-white mb-1 text-base group-data-[hover]:text-white/80">
                                  {t('task_readme_title')}
                                </span>
                                <ChevronDownIcon className="h-5 w-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180"/>
                            </DisclosureButton>
                            <DisclosurePanel className="mt-2 text-sm/6 text-gray-400 space-y-2">
                                {t(`${selectedScenario.readme}`)}
                            </DisclosurePanel>
                        </Disclosure>
                        {/*        <Disclosure as="div" className="p-6">
                            <DisclosureButton className="group flex w-full items-center justify-between">
                                <span className="text-sm/6 font-medium text-white group-data-[hover]:text-white/80">
                                  Do you offer technical support?
                                </span>
                                <ChevronDownIcon className="h-5 w-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180"/>
                            </DisclosureButton>
                            <DisclosurePanel className="mt-2 text-sm/5 text-white/50">No.</DisclosurePanel>
                        </Disclosure>*/}
                    </div>
                </div>
                <iframe
                    title="Unity WebGL"
                    src="/unity/index.html"
                    width="900"
                    height="550"
                    frameBorder="0"
                    onLoad={() => {
                        const iframe = document.querySelector('iframe');
                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                        const canvas = iframeDoc.querySelector('canvas');
                        if (canvas) {
                            canvas.style.width = '900px';
                            canvas.style.height = '550px';
                        }
                    }}
                ></iframe>
                <div className={"flex justify-evenly items-center w-full"}>
                    <video className="w-[380px] lg:w-[480px] xl:w-[600px] max-h-[350px]" key={brainVideoToShow} autoPlay muted loop>
                        <source src={brainVideoToShow} type="video/mp4"/>
                    </video>
                    <video className={`w-[380px] lg:w-[400px] xl:w-[500px] max-h-[350px] ${scenarioVideoToShow.includes('foo') ? 'hidden' : ''}`} key={scenarioVideoToShow} autoPlay muted loop>
                        <source src={scenarioVideoToShow} type="video/mp4"/>
                    </video>
                </div>
            </div>
            {/*EngineReadme*/}
            <div className={`${engineReadmeIsOpen ? '' : 'hidden'} fixed inset-0 w-screen overflow-y-auto bg-black/80 z-10 flex items-center justify-center `}>
                <div className="w-full max-w-2xl rounded-xl bg-white/10 px-10 pt-10 pb-5 backdrop-blur-2xl overflow-y-auto">
                    <h3 className="text-lg/7 font-medium text-white">{t('engine_readme_title')}</h3>
                    <div className="mt-4 text-base/7 text-white/60 space-y-2">
                        {Array.isArray(engineReadmeTextList) && engineReadmeTextList.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                    <div className="mt-8 flex justify-center">
                        <Button
                            className="w-full px-4 text-sm font-medium text-white tracking-wide bg-white/5 hover:bg-white/10 transition my-2 py-2 rounded-lg cursor-pointer"
                            onClick={closeEngineReadme}
                        >
                            {t('engine_readme_close_button')}
                        </Button>
                    </div>
                </div>
            </div>
            {/*NeuronCarousel*/}
            <div className={`${neuronCarouselIsOpen ? '' : 'h-0 w-0'} fixed inset-0 w-screen overflow-y-auto bg-black z-20 flex items-center justify-center`}>
                <div className="w-screen h-screen px-0 pt-0 pb-5 backdrop-blur-2xl overflow-y-auto">
                    <div className="flex flex-col min-h-full items-center justify-center p-0">
                        <div className={"rounded-3xl bg-cover bg-black/80 bg-blend-multiply bg-center bg-no-repeat md:w-[50%] lg:w-[836px] min-h-[300px] lg:min-h-[600px] flex flex-col justify-center items-center p-6 lg:p-0 mt-16"}
                             style={{backgroundImage: `url(${heroBackgroundImage})`}}
                        >
                            <h3 className={`text-5xl lg:text-6xl text-center font-semibold bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-neutral-50/100 to-neutral-300/30 leading-normal lg:leading-loose ${isChinese ? 'tracking-[.2em]' : 'tracking-[.02em] '}`}>{t('engine_frontpage_title')}</h3>
                        </div>
                        <p className="mb-24 lg:mb-36 mx-12 leading-relaxed text-xl font-light text-neutral-300 font-sans md:w-[50%] lg:w-[776px]">{t('engine_frontpage_instruction')}</p>
                        <NeuronCarousel/>
{/*                        <div className="mt-16 flex justify-center">
                            <Button
                                className="w-full px-4 text-sm font-medium text-white tracking-wide bg-white/5 hover:bg-white/10 transition my-2 py-2 rounded-lg cursor-pointer"
                                onClick={closeNeuronCarousel}
                            >
                                {t('engine_frontpage_close_button')}
                            </Button>
                        </div>*/}
                        <div className={"mt-24 lg:mt-36 mb-24 mx-5 lg:mx-2 rounded-2xl bg-cover bg-black/60 hover:bg-black/50 bg-blend-multiply bg-center bg-no-repeat w-[90%] md:w-[50%] lg:w-[836px] min-h-[250px] transition duration-150 flex flex-col justify-center items-center p-6 lg:p-0 cursor-pointer"}
                             style={{backgroundImage: `url(${humanEngineButtonBackgroundImage})`}} onClick={closeNeuronCarousel}
                        >
                            <div className="font-serif italic w-full text-xl text-center font-normal text-white tracking-wide rounded-lg">
                                {t('engine_frontpage_close_button_1')}
                            </div>
                            <div className={`w-full text-4xl lg:text-5xl text-center font-semibold text-white mt-4 rounded-lg ${isChinese ? 'tracking-[.2em]' : 'tracking-[.02em] font-mono '}`}>
                                {t('engine_frontpage_close_button_2')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrainSimulation;
